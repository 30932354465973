<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="IPD - Register"
                btnTitle="Admission"
                @onClickAddNewButton="onClickAdd"
            />
        </div>
       
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onSearch"
                :isHideDate="true"
            >
                <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Search</label>
                    <AsyncSelect
                        placeholder="Patient Name, ID, Mobile No"
                        v-model="params.patient"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ type: 'patient'}"
                        :format-label="formatPatientLabel"
                    />
                </div>

                <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Admission no</label>
                    <AsyncSelect
                        :key="admissionComp"
                        placeholder="Admission no"
                        v-model="params.admissionNo"
                        :api-service="fetchIpdRegNumbers"
                        :additional-query="{ contact_profile_id: contactId }"
                        :format-label="formatPatientLabel"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                    <label for="colFormLabel" class="col-form-label">Select Consultant </label>
                    <AsyncSelect
                        placeholder="Select consutant"
                        v-model="params.doctor"
                        :api-service="fetchServiceList"
                        :additional-query="{ resource_type: 'human_resources' }"
                        :format-label="formatPatientLabel"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Select Bed/Cabin </label>
                    <AsyncSelect
                        placeholder="Select Bed/Cabin"
                        v-model="params.place"
                        :api-service="fetchServiceList"
                        :additional-query="{ resource_type: 'places' }"
                        :format-label="formatPatientLabel"
                    />
                </div>

            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-2">
            <ListTable :tableItems="tableItems"/>
        </div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
    import TitleButton from '@/components/atom/TitleButton';
    import ListTable from '@/components/molecule/company/hospital/ipd-register/IpdRegisterTable.vue';
    import { computed, inject, onMounted, reactive, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import handleHospital from '@/services/modules/hospital'
    import Loader from '@/components/atom/LoaderComponent'
    import Pagination from '@/components/atom/Pagination'
    import { useStore } from 'vuex';
    import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
    import handleContact from "@/services/modules/contact";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";

    const $router = useRouter();
    const $route = useRoute();
    const $store = useStore();
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const loading = ref(false)
    const { fetchContactProfiles } = handleContact()
    const { formatPatientLabel } = useAsyncDropdownHelper()
    const { 
        fetchIPDRegistrationList, 
        fetchIpdRegStatus, 
        fetchServiceList, 
        fetchIpdRegNumbers 
    } = handleHospital()
    const params = reactive({
        start_date: $route.query.start,
        end_date: $route.query.end,
        searchTxt: '',
        status: null,
        offset: 20,
        patient: null,
        doctor: null,
        place: null,
        regStatus: null,
        admissionNo: null,
    });
    const registrationStatus = reactive([])
    const tableItems = ref([]);
    const admissionComp = ref(null)

    const start = computed(() => $route.query.start);
    const end = computed(() => $route.query.end);
    const contactId = computed(() => params.patient ? params.patient.id : '')

    watch(contactId, (newVal, oldVal) => {
        admissionComp.value = newVal;
        params.admissionNo = null;
    })

    //Functions
    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    };

    const onSearch = () => {
        getRegistrationList();
    }

    const setPagination = (data) => {
        setPaginationData({
            records: data.total,
            perPage: data.per_page,
            currentPage: data.current_page,
        })
    }

    const onClickAdd = () => {
        $router.push({name: 'ipd-admission',
            params: $route.params,
            query: $route.query,
        })
    }

    async function onPageChange(page) {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        await $router.push({path: $route.path, query: routeQuery})
        getRegistrationList();
    }

    function getQuery(onMounted = false) {
        let query = `?company_id=${$route.params.companyId}`;
        if ($route.query.page) query += '&page=' + $route.query.page
        if(params.searchTxt) query += `&searchTxt=${params.searchTxt}`;
        if (params.regStatus) query += '&reg_status=' + params.regStatus
        if (onMounted) query += '&reg_status=admitted'
        if (params.patient) query += '&patient_id=' + params.patient.id
        if (params.doctor) query += '&doctor_id=' + params.doctor.id
        if (params.place) query += '&place_id=' + params.place.id
        if (params.admissionNo) query += '&admission_id=' + params.admissionNo.id
        query += '&offset=' + params.offset
        return query;
    }

    const getRegistrationList = async (onMounted = false) => {
        let query = getQuery(onMounted);
        loading.value = true
        await fetchIPDRegistrationList(query).then(res => {
            loading.value = false
            if (!res.status){
                $store.commit("resetPagination")
                tableItems.value = [];
                return;
            }
            tableItems.value = res.data.data;
            setPagination(res.data.meta)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })
    }

    const onClickGo = () => {
        
    }

    const getRegStatus = () => {
        let query = `?company_id=${$route.params.companyId}`;
        fetchIpdRegStatus(query).then(res=> {
            if(!res.data) return;
            registrationStatus.push(...res.data);
            params.regStatus = 'admitted';
        })
    }

    onMounted( async() => {
        await Promise.all([
            getRegistrationList(true),
            getRegStatus(),
        ])
    });
</script>